<template>
  <section id="survey-channel-email">
    <div class="vx-row">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vx-card
          :title="
            lang.surveyIntegrations.channels.whatsapp.settings.title[
              languageSelected
            ]
          "
          class="overflow-hidden mb-2"
        >
          <div class="vx-row">
            <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
              <EmailBuilder :bot="bot" v-model="email" />
            </vs-col>
            <vs-col :vs-lg="'6'" :vs-xs="'12'" :vs-sm="'12'">
              <Audience
                type="email"
                v-model="audience"
                @timezoneChange="timezoneChange"
                @expirationChange="expirationChange"
                @expirationMessageChange="expirationMessageChange"
              />
            </vs-col>
          </div>

          <div class="vx-row">
            <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
              <Scheduler
                :showTitle="false"
                :showTimezone="false"
                :showActions="false"
                :visible="false"
                ref="scheduler"
                @onSelect="sendScheduledSurvey"
              />

              <vs-button
                class="mt-3 mb-0 float-left"
                icon-pack="feather"
                icon="icon-send"
                type="filled"
                color="#9ecc38"
                @click="sendSurvey"
              >
                {{
                  lang.surveyIntegrations.channels.whatsapp.settings.buttons
                    .send[languageSelected]
                }}
              </vs-button>

              <vs-button
                class="mt-3 mb-0 ml-3 float-left"
                icon-pack="feather"
                icon="icon-clock"
                type="flat"
                color="#9ecc38"
                @click="openScheduler"
              >
                {{
                  lang.surveyIntegrations.channels.whatsapp.settings.buttons
                    .scheduledSend.title[languageSelected]
                }}
              </vs-button>
            </vs-col>
          </div>
        </vx-card>
      </vs-col>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import services from '../services'
export default {
  name: 'SurveyChannelEmail',
  props: {
    survey: Object,
    bot: Object,
    integration: Object,
    surveyForm: String
  },
  data() {
    return {
      email: {
        from: '',
        subject: this.survey.service.name,
        html: `<p>Hola!</p><p><br></p><p>Por favor, haz clic <a href="${this.surveyForm}" rel="noopener noreferrer" target="_blank">aquí</a> para realizar la encuesta.</p><p><br></p><p>Muchas gracias!</p>`,
        href: this.surveyForm
      },

      audience: [],
      timezone: null,
      expiration: null,
      expirationMessage: null,

      MAX_AUDIENCE_LENGTH: 10 * 1000 * 10, // 10k users
      MAX_AUDIENCE_SIZE: 1000 * 1000 * 10 // 10mb payload size
    }
  },
  components: {
    EmailBuilder: () => import('./Components/EmailBuilder.vue'),
    Audience: () => import('./Components/Audience.vue'),
    Scheduler: () => import('@/components/scheduler/Scheduler.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    getConfirmationText() {
      let text = `${
        this.lang.surveyIntegrations.channels.whatsapp.messages.confirm.survey[
          this.languageSelected
        ]
      } <${this.survey.service.name}> ${
        this.lang.surveyIntegrations.channels.whatsapp.messages.confirm.bot[
          this.languageSelected
        ]
      } <${this.bot.name}> ${
        this.lang.surveyIntegrations.channels.whatsapp.messages.confirm.channel[
          this.languageSelected
        ]
      } <${this.integration.type}>, `

      if (this.audience.length > 3) {
        text += `${
          this.lang.surveyIntegrations.channels.whatsapp.messages.confirm
            .audience[this.languageSelected]
        } <${this.audience[0].email}, ${this.audience[1].email}, ${
          this.audience[2].email
        }> y ${this.audience.length - 3}+`
      } else {
        text += `${
          this.lang.surveyIntegrations.channels.whatsapp.messages.confirm
            .audience[this.languageSelected]
        } <${this.audience.map(element => element.email).join(', ')}>`
      }

      return text
    },
    sendSurvey() {
      const isValidSurvey = this.validateSurvey()

      if (isValidSurvey) {
        const text = this.getConfirmationText()

        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.confirm
            .title[this.languageSelected],
          text: text,
          acceptText: this.lang.surveyIntegrations.channels.whatsapp.messages
            .confirm.buttons.accept[this.languageSelected],
          cancelText: this.lang.surveyIntegrations.channels.whatsapp.messages
            .confirm.buttons.cancel[this.languageSelected],
          accept: async () => this.send()
        })
      }
    },
    openScheduler() {
      this.$refs.scheduler.openPopup()
    },
    sendScheduledSurvey(schedule) {
      const isValidSurvey = this.validateSurvey()

      if (isValidSurvey) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: this.lang.surveyIntegrations.channels.whatsapp.settings
            .scheduleConfirm.title[this.languageSelected],
          text: `${
            this.lang.surveyIntegrations.channels.whatsapp.settings
              .scheduleConfirm.text[this.languageSelected]
          } ${this.$moment(schedule.date).format('DD-MM-YYYY, h:mm:ss A')}?`,
          acceptText: this.lang.surveyIntegrations.channels.whatsapp.messages
            .confirm.buttons.accept[this.languageSelected],
          cancelText: this.lang.surveyIntegrations.channels.whatsapp.messages
            .confirm.buttons.cancel[this.languageSelected],
          accept: async () => this.send(schedule)
        })
      }
    },
    async send(schedule = null) {
      this.$vs.loading()
      try {
        let staging = this.email.staging
        delete this.email.staging
        const data = {
          survey: {
            service: this.$route.query.service
          },
          timeOffset: this.timezone,
          config: {
            email: {
              // elementName: this.elementName,
              // languageCode: this.languageCode,
              // variables: this.variables,
              // html: this.email.html,
              ...this.email,
              audience: this.audience
            }
          },
          staging: staging
        }
        // if (this.confirmation) {
        //   data.confirmation = {
        //     active: true,
        //     firstMessage: this.firstMessage,
        //     rejectedMessage: this.rejectedMessage,
        //     resendMessage: this.resendMessage
        //   }
        // }
        if (schedule && schedule.date) {
          data.schedule = schedule.date
        }
        if (this.expiration) {
          data.expiration = this.expiration
          data.expirationMessage = this.expirationMessage
        }

        await services.addSurvey(this.bot._id, data)

        this.$vs.loading.close()
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.success
            .title[this.languageSelected],
          text: this.lang.surveyIntegrations.channels.whatsapp.messages.success
            .text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
        this.$router.push({
          path: '/surveys-data',
          query: {
            survey: this.$route.query.survey,
            bot: this.$route.query.bot
          }
        })
      } catch (ex) {
        // TODO: handle error
        this.$vs.loading.close()
        console.error(ex)
        this.$vs.notify({
          title: 'Oops!',
          text: `Parece que ocurrió un error`,
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    validateSurvey() {
      if (!this.audience.length) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
            .emptyAudience.title[this.languageSelected],
          text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
            .emptyAudience.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })

        return false
      }
      if (this.audience.length > this.MAX_AUDIENCE_LENGTH) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.tooManyRecipients.title[
            this.languageSelected
          ],
          text: this.lang.surveyIntegrations.tooManyRecipients.text[
            this.languageSelected
          ].replace('XXX', this.MAX_AUDIENCE_LENGTH),
          color: 'warning',
          position: 'top-right'
        })

        return false
      }
      if (JSON.stringify(this.audience).length > this.MAX_AUDIENCE_SIZE) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.tooManyBytes.title[
            this.languageSelected
          ],
          text: this.lang.surveyIntegrations.tooManyBytes.text[
            this.languageSelected
          ].replace('XXX', this.MAX_AUDIENCE_SIZE),
          color: 'warning',
          position: 'top-right'
        })

        return false
      }

      if (!this.email.from || !this.email.subject || !this.email.html) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.email.messages.error
            .emptyEmail.title[this.languageSelected],
          text: this.lang.surveyIntegrations.channels.email.messages.error
            .emptyEmail.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })

        return false
      }
      if(this.expiration && !this.expirationMessage){
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
                  .missingExpirationMessage.title[this.languageSelected],
          text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
                  .missingExpirationMessage.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })

        return false
      }

      // if (!this.elementName || !this.languageCode) {
      //   this.$vs.notify({
      //     title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
      //       .missingFields.title[this.languageSelected],
      //     text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
      //       .missingFields.text[this.languageSelected],
      //     color: 'warning',
      //     position: 'top-right'
      //   })

      //   return false
      // }
      // if (this.confirmation) {
      //   if (
      //     !this.firstMessage ||
      //     this.firstMessage.trim().length === 0 ||
      //     !this.rejectedMessage ||
      //     this.rejectedMessage.trim().length === 0 ||
      //     !this.resendMessage ||
      //     this.resendMessage.trim().length === 0
      //   ) {
      //     this.$vs.notify({
      //       title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
      //         .missingConfirmationFields.title[this.languageSelected],
      //       text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
      //         .missingConfirmationFields.text[this.languageSelected],
      //       color: 'warning',
      //       position: 'top-right'
      //     })

      //     return false
      //   }
      // }

      return true
    },
    timezoneChange(timezone) {
      this.timezone = timezone
    },
    expirationChange(expiration) {
      // le sacamos lo que tenga de offset
      this.expiration = new Date(
        expiration.getTime() - new Date().getTimezoneOffset() * 60 * 1000
      ).toISOString()
    },
    expirationMessageChange(expirationMessage) {
      this.expirationMessage = expirationMessage
    }
  }
}
</script>
